import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { mobileSize } from "../../../util/variables";
import { navigate } from "gatsby";
import theme from "../../../util/theme";
import { subscription_success } from "../../../services/mixpanel/mixpanel";
import { AccountForm } from "../../../components/PageSegments/subscription/AccountForm";
import { BasicLayout } from "../../../layouts/BasicLayout";
import { getStripePaymentData } from "../../../actions/stripeAction";
import { saveUserData } from "../../../services/firebase";
import { AppLogo } from "../../../components/ui";

export default (data: any) => {
  const { search } = data.location;
  const urlParams = new URLSearchParams(search);
  const subTypeParam = urlParams.get("subscription_type") ?? "yearly";
  const sessionParam = urlParams.get("session_id");

  const [stripeData, setStripeData] = useState(null);

  useEffect(() => {
    const initForm = async () => {
      try {
        const res = await getStripePaymentData(sessionParam ?? "");
        saveUserData(await res.email);
        setStripeData(res);
      } catch (err) {
        console.log(err);
        navigate("/");
      }
    };

    initForm();
    subscription_success({ subscription_type: subTypeParam });
  }, [sessionParam, subTypeParam]);

  return (
    <BasicLayout noIndex>
      <FlexContainer>
        <LogoContainer>
          <AppLogo white size="medium" />
        </LogoContainer>
        <AccountForm subscriptionType={subTypeParam} data={stripeData} free={false} />
      </FlexContainer>
    </BasicLayout>
  );
};

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background: ${theme.PRIMARY_COLOR};

  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;

// const Line = styled.hr`
//   width: 588px;
//   height: 0px;
//   border: 2px solid #fefefe;
//   margin: 40px 0px;

//   @media ${mobileSize} {
//     display: none;
//     margin: 0;
//   }
// `;

// const LogoContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: flex-start;
//   margin-top: 100px;
//   @media ${mobileSize} {
//     margin-top: 64px;
//   }
// `;

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    position: static;
    margin: 64px 0;
  }
`;
